<template>
  <div>
    <template>
      <div class="page">
        <div class="page__title">
          {{ getModuleConfig(`${this.status}_page.form_title`) }}
        </div>
        <div
          class="page__desc"
          v-if="getModuleConfig(`${this.status}_page.form_desc`)"
        >
          {{ getModuleConfig(`${this.status}_page.form_desc`) }}
        </div>
        <div v-if="fetching">
          <Loading />
        </div>
        <div v-else>
          <template v-if="bookings.length">
            <ul>
              <li class="s-mb-3" v-for="(booking, i) in bookings" :key="i">
                <Item
                  :booking="booking"
                  :preset="preset"
                  :show-image="false"
                  :field-setting="fieldSetting"
                  :booking-status-mapping="bookingStatusMapping"
                  :status-detector-config="statusDetectorConfig"
                  @fetch="fetchBookings"
                />
              </li>
            </ul>
            <Pagination v-model="page" :total-page="lastPage" />
          </template>
          <div class="s-pt-40 s-text-center" v-else>
            <img class="s-mb-3" src="@/assets/images/liff/empty.svg" />
            <p class="s-text-gray">目前無資料</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import Item from "@/components/Page/Liff/Booking/Item";
import Pagination from "@/components/Page/Liff/Shared/Pagination";
import bookingApi from "@/apis/liff/v2/booking";
import bookingMixin from "@/mixins/liff/booking";
import _ from "lodash";

export default {
  mixins: [bookingMixin],
  computed: {
    startDate() {
      return this.$route.query.startAt;
    },
    endDate() {
      return this.$route.query.endAt;
    },
    status() {
      return this.$route.params.status;
    },
    statusDetectorConfig() {
      return this.preset?.config?.status_detector ?? [];
    }
  },
  components: {
    Loading,
    Item,
    Pagination,
  },
  data() {
    return {
      fetching: false,
      bookings: [],
      page: +this.$route.query.page || 1,
      lastPage: 1,
    };
  },
  created() {
    this.debouncedFetchBookings = _.debounce(() => {
      this.fetching = true;
      this.fetchBookings()
    }, 500);
  },
  watch: {
    page() {
      this.debouncedFetchBookings();
    },
    startDate() {
      this.debouncedFetchBookings();
    },
    endDate() {
      this.debouncedFetchBookings();
    },
  },
  mounted() {
    this.fetching = true;
    this.fetchProvider().then(() => {
      this.init();
      this.setQuery().then(() => this.debouncedFetchBookings());
    });
  },
  methods: {
    async fetchBookings() {
      try {
        const data = await bookingApi.getList({
          page: this.page,
          status: this.statusFilterMapping[this.status]
            ? this.statusFilterMapping[this.status].join(",")
            : this.status,
          start_at: this.startDate,
          end_at: this.endDate,
          preset_id: this.presetId,
        });
        this.bookings = data.data;
        this.lastPage = data.lastPage;
        this.fetching = false;
      } catch (error) {
        this.fetching = false;
        console.error(error);
      }
    },
  },
};
</script>
