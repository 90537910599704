<template>
  <div v-if="isPermission && isIncludeStatus" class="mr-2">
    <div v-if="buttonTemplate == 'member_button'">
      <MemberButton
        :button-text="statusMessage"
        :type="buttonAction"
      >
      </MemberButton>
    </div>
    <div v-else-if="buttonTemplate == 'shared_button'">
      <SharedButton :class="statusClass" @click="clickAction" :variant="statusVariant">
        <i :class="statusIcon"></i> {{ statusMessage }}
      </SharedButton>
    </div>
    <div v-else>
      <b-button
        :variant="statusVariant"
        :class="statusClass"
        @click="clickAction"
      >
        <i :class="statusIcon"></i> {{ statusMessage }}
      </b-button>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";

  export default {
    components: {
      MemberButton,
      SharedButton
    },
    props: {
      status: {
        type: String,
        default: ''
      },
      enviroment: {
        type: String,
        default: ''
      },
      config: {
        type: Object,
        default: () => ({
          // "button_text": "同意報價", v
          // "status": ["pending"], // 這個按鈕只有在pending狀態下才會出現
          // "button_style": "s-btn-bg-primary", // 按鈕的顏色 v
          // "button_icon": "check", // 按鈕的icon v
          // "button_action": "update_status", // 按下按鈕後，會執行update_status ,也支援 member_button_action
          // "action_target": "agreed", // 這個根據上面Action行為 如果是update_status 就是更新狀態 如果是Member Button 就是OA Close那類
          // "permission": "liff" // 按鈕的權限
        })
      }
    },
    data: () => ({}),
    mounted() {
    },
    computed: {
      statusClass () {
        return this.config?.button_style ?? 's-btn-bg-primary'
      },
      statusIcon () {
        return this.config?.button_icon ?? ''
      },
      statusMessage () {
        return this.config?.button_text ?? ''
      },
      isIncludeStatus () {
        return this.config?.status?.includes(this.status) ?? false;
      },
      isPermission () {
        return this.config?.permission === this.enviroment
      },
      buttonTemplate () {
        return this.config?.button_template ?? ''
      },
      statusVariant() {
        return this.config?.button_variant ?? ''
      },
      buttonAction() {
        return this.config?.action_target ?? ''
      },
      alertMessage() {
        return this.config?.confirm_text ?? '確認是否要執行此操作?'
      }
    },
    methods: {
      async clickAction() {
        this.$swal({
          text: this.alertMessage,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '確定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.$emit('buttonAction', this.config?.action_target)
          }
        })
      },
    },
  }
</script>
